// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //url: 'https://localhost:44321/api/',
  url: 'https://ws-app-sisagente.stigacx-on.com/api/',
  apikey:'',
  firebaseConfig : {
    apiKey: "AIzaSyBIZiGHq5cNK8WgLlUH7cY75uldVv_zzIQ",
    authDomain: "sisagente-129d5.firebaseapp.com",
    projectId: "sisagente-129d5",
    storageBucket: "sisagente-129d5.appspot.com",
    messagingSenderId: "478007345408",
    appId: "1:478007345408:web:9195d3f2d1e9da266833e5",
    measurementId: "G-EVEBVH35WK"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
