import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ParametrosSentencia, Usuario } from 'src/app/interfaces/interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { DatalocalService } from 'src/app/services/datalocal.service';

import { AlertController } from '@ionic/angular';
import { SISAgenteService } from 'src/app/services/sisagente.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {

  @Output() typeChanged = new EventEmitter<boolean>();  

  public usuario:Usuario;

  constructor(private auth: AuthService,
              private navCrl: NavController,
              private datalocal: DatalocalService,
              private SISAgente: SISAgenteService,
              public alertController: AlertController) {     
  }

  ngOnInit() {
    this.recuperarUsuario();        
  }
  

  bajandoScroll(){
    window.onscroll = function(){
      if(document.documentElement.scrollTop > 1){
        document.querySelector('.navBar').classList.add('bajando');
      }else{
        document.querySelector('.navBar').classList.remove('bajando');
      }
    }
  }

  menuDesplegablePerfil(){
    const menuPerfil = document.querySelector('.menuPerfil');
    menuPerfil.classList.toggle('active')
  }

  recuperarUsuario(){
    this.auth.recuperarUsuario().then(u => {
      //console.log(u);      
      this.usuario = u;      
    }).catch( y => {
      
    });
  }

  //Cerrar Sesion
  cerrarSesion() {
    this.marcar(this.usuario.codigo,"Salida del SiSAgente",true);
    this.marcar(this.usuario.codigo,"Marcaje correcto",false);

    var parametros: ParametrosSentencia = {      
      NombreUsuario: this.usuario.codigo,   
    }    
    this.SISAgente.ejecutarConsulta(["227"], parametros, "").then(x => {}).catch(z =>  { 
      //console.log('error', z);  
      this.alerta("No se ha podido inicializar");   
    });

    this.auth.resetearUsuario().then(() => {
      this.navCrl.navigateRoot(`/login`, { animated: true });
    });
  }
  //-------------

  //Marcar
  marcarHorario(){
    this.marcar(this.usuario.codigo,"Inicio SiSAgente",true);
    this.marcar(this.usuario.codigo,"Marcaje correcto",false);
  }

  marcar(nombreUsuario: string, motivo: string, mensaje: boolean){
    var parametros: ParametrosSentencia = {      
      NombreUsuario: nombreUsuario,
      Motivo : motivo      
    }  
  
    this.SISAgente.ejecutarConsulta(["232"], parametros, "").then(x => {
      if(mensaje===true){
        this.alerta("¡Has marcado correctamente!");
      }
    }).catch(z =>  { 
      //console.log('error', z);  
      this.alerta("No se ha podido realizar el marcaje");   
    });
  }
  //--------------

  //Cambio Contraseña
  cambiarContrasena(){
    this.navCrl.navigateRoot(`/cambiar-contrasena`, { animated: true });
  }

  actualizarPagina(){
    this.typeChanged.emit();
  }
  //--------------

  async alerta(mensaje: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',           
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }
}
