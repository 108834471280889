import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DatalocalService {  
  loading:any;

  constructor(private loadinCtrl: LoadingController) { }  

  async presentLoading( mensaje: string) {
    this.loading = await this.loadinCtrl.create({
      message: mensaje,
      cssClass:'newLoading', 
    });
    return this.loading.present();
  }

  async closeLoading() {
    this.loadinCtrl.getTop().then(v => v ? this.loadinCtrl.dismiss() : null);
  }

  delayedCloseLoading() {
    setTimeout(() => {
      this.loadinCtrl.dismiss();
    }, 3000);   
  } 
  
  
}
