import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ParametrosSentencia } from '../interfaces/interfaces';
import { AuthService } from './auth.service';


const URL = environment.url;
// const authToken = environment.apikey;

@Injectable({
  providedIn: 'root'
})
export class SISAgenteService {
  token:string = null;

  

  constructor(private auth: AuthService,
              private http: HttpClient) { }

  ejecutarConsulta(NumeroSentencia:string[], ParametrosSentencia: ParametrosSentencia, Identificador: string ) {
    //console.log("NumeroSentencia ->" + NumeroSentencia)
    //console.log("ParametrosSentencia ->" + ParametrosSentencia)
    const data = {NumeroSentencia,ParametrosSentencia,Identificador};    
    //console.log(data)
    return new Promise<any>((resolve, reject) => {
      this.auth.recuperarToken().then(x => {
        //console.log(x); 
        this.http.post(`${URL}Sentencias`, data,
        {
          headers: new HttpHeaders()
            .set('Authorization',`Bearer ${x}`)
            .set('Content-Type', 'application/json')
          })
          .subscribe((resp:any) => { 
            var numeroSentenciaString:string = ""
            NumeroSentencia.forEach(element => {
              numeroSentenciaString += element + " - "
            }); 
            numeroSentenciaString = numeroSentenciaString.substring(0, numeroSentenciaString.length - 2)       
            //console.log(numeroSentenciaString, "Numero/s Sentencia");
            //console.log(ParametrosSentencia, "Parametro Sentencia");
            //console.log('RESPUESTA', resp);
            resolve(resp);
          }, error => {
            //console.log("Error", error); 
            reject('error')           
          });
        });
      })    
  }
}
