import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(
    private storage: Storage,
    private navCtrl: NavController) { }
    
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.storage.get('usuario').then(x => {
      if (x) {
        //  alert('ok')
        return true;
      } else {
        //  alert('error')
        this.navCtrl.navigateRoot(`/login`, {animated: true});
        return false;
      }
    });
  }
}
