import { Injectable } from '@angular/core';
import { Usuario } from '../interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';


const URL = environment.url;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token:string = null;
  usuario:Usuario;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private auth: AngularFireAuth) {
    
  }

  login(){
    //console.log("2")

    let provider = new firebase.default.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({      
      //prompt: 'consent',
      tenant: "d2db489c-4a07-4132-b731-068f7b0f0747",
      
    });
    return new Promise<string>( resolve => {
      this.auth.signInWithPopup(provider).then((result) => {
        //console.log('RESULT',result);
        
        const credential = result.credential as firebase.default.auth.OAuthCredential;
        const user = result.user; 
        const token = credential.idToken;
        this.guardarToken(token).then(() => {
          resolve(user.email.substring(0,user.email.indexOf('@')));
          //console.log(token);
          
        })
      }).catch(y => {
        resolve(null);
      });
    });
  }

  obtenerTokenBueno(codigoUsuario:string) {
    const data = {codigoUsuario};
    
    return this.recuperarToken().then(x => {
      return new Promise<Usuario>( resolve => {    
        //console.log('MANDO AL AUTH',data);
        //console.log("auth",x);
        //console.log("obtenerTokenBueno",data)
        this.http.post(`${URL}Users/Authenticate`, data,
        {
          headers: new HttpHeaders()
            .set('Authorization',`${x}`)
            .set('Content-Type', 'application/json')
        })
        .subscribe((resp : Usuario) => {      
          //console.log('RESPUESTA DE AUTH', resp);
          //console.log("sus")
          this.guardarToken(resp['token']).then(() => {                      
            resolve(resp)            
          })          
        }, error => {
          //console.log('error del atuh',error);
          
          resolve(null);
        });
      });
    })
    
  }

  async guardarToken(token: string) {
    //console.log("Token Guardado: ", token);
    this.token = token;
    await this.storage.set('token', token);
  }

  async guardarUsuario(usuario: Usuario) {    
    this.usuario = usuario;
    await this.storage.set('usuario', usuario);
  }

  async resetearUsuario() {
    await this.storage.remove('usuario');
  }

  async recuperarUsuario() {
    this.usuario = await this.storage.get('usuario');
    return  this.usuario;
  }

  async recuperarToken() {
    this.token = await this.storage.get('token');
    return  this.token;
  }

}
