import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MotivosBloqueo, Usuario } from 'src/app/interfaces/interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { DatalocalService } from 'src/app/services/datalocal.service';
import { SISAgenteService } from 'src/app/services/sisagente.service';

@Component({
  selector: 'app-pantalla-bloqueo',
  templateUrl: './pantalla-bloqueo.component.html',
  styleUrls: ['./pantalla-bloqueo.component.scss'],
})
export class PantallaBloqueoComponent implements OnInit {

  idUsuario:String;
  encontrado: boolean;
  
  passwordtxtDesbloqueo: string;

  @Input() nombreUsuario: string;
  @Input() motivoAusencia: MotivosBloqueo;

  @Output() typeChanged = new EventEmitter<boolean>();  

  constructor(private auth: AuthService,
              private datalocal: DatalocalService,
              private SISAgente: SISAgenteService) 
  {   
    
  }

  ngOnInit( ) {    
    this.colorFondoMotivoBloqueo();    
  }

  desbloqueo(){
    this.typeChanged.emit(false);
  } 

  // loginDesbloqueo(){
  //   this.datalocal.presentLoading('Espere...')  
    
    

  //   this.auth.login_Old(this.nombreUsuario, this.passwordtxtDesbloqueo) .then(x => {
  //     if (x) {
  //       this.auth.recuperarUsuario().then(u => {
  //         //console.log(u);          
  //         this.encontrado = true; 
  //         this.typeChanged.emit(false);
  //         this.datalocal.closeLoading();          
  //       }).catch( y => {  
  //         this.encontrado = false;          
  //         this.typeChanged.emit(true);
  //         this.datalocal.closeLoading();      
  //       });    
  //     }else {
  //       this.encontrado =false;       
  //       this.typeChanged.emit(true);
  //       this.datalocal.closeLoading();
  //     }
  //   }).catch(y => {
  //     this.encontrado =false;     
  //     this.typeChanged.emit(true);
  //     this.datalocal.closeLoading();
  //   })
  // } 

  colorFondoMotivoBloqueo(){
    document.getElementById('pantallaBloqueo').style.background = this.motivoAusencia.color;    
  }  
}
