import { Component, OnInit } from '@angular/core';
import { AlertController,  } from '@ionic/angular';
import {  Usuario } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-nav-bar-simple',
  templateUrl: './nav-bar-simple.component.html',
  styleUrls: ['./nav-bar-simple.component.scss'],
})
export class NavBarSimpleComponent implements OnInit {

  public usuario:Usuario;

  constructor(public alertController: AlertController) {     
  }

  ngOnInit() { }  

  bajandoScroll(){
    window.onscroll = function(){
      if(document.documentElement.scrollTop > 1){
        document.querySelector('.navBar').classList.add('bajando');
      }else{
        document.querySelector('.navBar').classList.remove('bajando');
      }
    }
  }

  menuDesplegablePerfil(){
    const menuPerfil = document.querySelector('.menuPerfil');
    menuPerfil.classList.toggle('active')
  }

  async alerta(mensaje: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',           
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }

}
